import $ from 'jquery';
import 'what-input';
window.$ = $;
// require('foundation-sites');
import './lib/foundation-explicit-pieces';

$(document).foundation();

$(document)
    .on('opened.zf.offCanvas', '[data-off-canvas]', function () {
        $("#js-menu-hamburger").children().toggleClass('open');
    })
    .on('closed.zf.offCanvas', '[data-off-canvas]', function () {
        $("#js-menu-hamburger").children().toggleClass('open');
    });



function filterAccordionItems(valueToSearchFor) {
    const ul = document.getElementById('js-faq');
    const items = ul.getElementsByTagName("li");
    for (let i = 0; i < items.length; ++i) {
        let item = items[i];
        if (item.innerHTML.toLowerCase().indexOf(valueToSearchFor.toLowerCase()) !== -1) {
            // items[i].style.display = '';
            item.classList.remove('is-filtered');
            setTimeout(function() {
                item.style.display = '';
            }, 300);
        } else {
            // items[i].style.display = 'none';
            item.classList.add('is-filtered');
            setTimeout(function() {
                item.style.display = 'none';
            }, 300);
        }
    }
}

$(document).on('submit', '#js-faq-form-search', function (e) {
    e.preventDefault();
    let valueToSearchFor = $("#js-faq-search-input").val();
    filterAccordionItems(valueToSearchFor);
    // Foundation.SmoothScroll.scrollToLoc('#faq', {
    //     threshold: 50,
    //     offset: 180,
    //     easing: 'swing',
    //     animationDuration: 600
    // }, function () {
    //     //    console.log('Everything went OK!');
    // });
});

let windowScrollTop;
const $_header = $("#js-site-header");
$(window).on('scroll load', function () {
    windowScrollTop = $(window).scrollTop();
    if (windowScrollTop >= 70) {
        $_header.addClass('is-scrolled');
        return false;
    } else {
        $_header.removeClass('is-scrolled');
    }
});